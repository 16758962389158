.reactMarkDown {
}

.reactMarkDown ul {
  margin-top: 1rem;
  margin-bottom: 1em;
  list-style: disc outside none;
}
.reactMarkDown ul li {
  margin-left: 1.2em;
  display: list-item;
  text-align: -webkit-match-parent;
}
