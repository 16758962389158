@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=Inter:wght@400;600;700&family=Montserrat:wght@700&display=swap');

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: 'Inter', sans-serif;
}

/* prevent  Error:ResizeObserver loop completed with undelivered notifications. when window hight is changed */
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
